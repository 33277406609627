import React, { useRef, useState, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useConfig } from '../ConfigProvider'
import cloneDeep from 'lodash/cloneDeep'
import FileItem from './FileItem'
import Button from '../Buttons'
import CloseButton from '../CloseButton'
import Notification from '../Notification'
import toast from '../toast'
import ImageCrop from 'components/shared/ImageCrop'

const filesToArray = (files) => Object.keys(files).map((key) => files[key])

const Upload = React.forwardRef((props, ref) => {
    const {
        allowCrop = false,
        accept,
        beforeUpload,
        disabled,
        draggable,
        fileList,
        multiple,
        onChange,
        onFileRemove,
        showList,
        tip,
        uploadLimit,
        children,
        className,
        field,
        form,
        bannerType,
        ...rest
    } = props

    const fileInputField = useRef(null)
    const [files, setFiles] = useState(fileList)
    const [dragOver, setDragOver] = useState(false)

    const { themeColor, primaryColorLevel } = useConfig()

    const [image, setImage] = useState(null)
    const [imgType, setImgType] = useState('')
    const [imageCropModal, setImageCropModal] = useState(false)
    const [imageForCrop, setImageForCrop] = useState('')
    const [aspectWidth, setAspectWidth] = useState('')
    const [aspectHeight, setAspectHeight] = useState('')

    useEffect(() => {
        setFiles(fileList)
    }, [fileList])

    const triggerMessage = (msg) => {
        toast.push(
            <Notification type="danger" duration={2000}>
                {msg || 'Upload Failed!'}
            </Notification>,
            {
                placement: 'top-center',
            }
        )
    }

    const pushFile = (newFiles, file) => {
        for (let f of newFiles) {
            file.push(f)
        }
        return file
    }

    const addNewFiles = (newFiles) => {
        let file = cloneDeep(files)
        if (typeof uploadLimit === 'number' && uploadLimit !== 0) {
            if (Object.keys(file).length >= uploadLimit) {
                if (uploadLimit === 1) {
                    file.shift()
                    file = pushFile(newFiles, file)
                }

                return filesToArray({ ...file })
            }
        }
        file = pushFile(newFiles, file)
        return filesToArray({ ...file })
    }

    const onNewFileUpload = (e) => {
        const { files: newFiles } = e.target
        let result = true

        if (beforeUpload) {
            result = beforeUpload(newFiles, files)

            if (result === false) {
                triggerMessage()
                return
            }

            if (typeof result === 'string' && result.length > 0) {
                triggerMessage(result)
                return
            }
        }

        if (allowCrop) {
            const fileToCrop = newFiles[0] // Assuming single file crop for simplicity
            if (fileToCrop) {
                onPhotoSelect({ target: { files: [fileToCrop] } })
            }
        } else {
            if (result) {
                let updatedFiles = addNewFiles(newFiles)
                setFiles(updatedFiles)
                onChange?.(updatedFiles, files)
            }
        }
    }

    const removeFile = (fileIndex) => {
        const deletedFileList = files.filter((_, index) => index !== fileIndex)
        setFiles(deletedFileList)
        onFileRemove?.(deletedFileList)
    }

    const triggerUpload = (e) => {
        if (!disabled) {
            fileInputField.current?.click()
        }
        e.stopPropagation()
    }

    const renderChildren = () => {
        if (!draggable && !children) {
            return (
                <Button disabled={disabled} onClick={(e) => e.preventDefault()}>
                    Upload
                </Button>
            )
        }

        if (draggable && !children) {
            return <span>Choose a file or drag and drop here</span>
        }

        return children
    }

    const handleDragLeave = useCallback(() => {
        if (draggable) {
            setDragOver(false)
        }
    }, [draggable])

    const handleDragOver = useCallback(() => {
        if (draggable && !disabled) {
            setDragOver(true)
        }
    }, [draggable, disabled])

    const handleDrop = useCallback(() => {
        if (draggable) {
            setDragOver(false)
        }
    }, [draggable])

    const draggableProp = {
        onDragLeave: handleDragLeave,
        onDragOver: handleDragOver,
        onDrop: handleDrop,
    }

    const draggableEventFeedbackClass = `border-${themeColor}-${primaryColorLevel}`

    const uploadClass = classNames(
        'upload',
        draggable && `upload-draggable`,
        draggable && !disabled && `hover:${draggableEventFeedbackClass}`,
        draggable && disabled && 'disabled',
        dragOver && draggableEventFeedbackClass,
        className
    )

    const uploadInputClass = classNames(
        'upload-input',
        draggable && `draggable`
    )

    const getImage = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image()

            const objectUrl = URL.createObjectURL(file)

            img.onload = function () {
                URL.revokeObjectURL(objectUrl)
                resolve(this)
            }
            img.src = objectUrl
        })
    }

    const onPhotoSelect = async (e) => {
        let image = e.target.files[0]
        if (
            e.target.files[0].type !== 'image/jpeg' &&
            e.target.files[0].type !== 'image/png'
        ) {
            triggerMessage(
                'Upload valid image. Check the image type in given notes'
            )
            return false
        }
        if (Math.round(e.target.files[0].size / 1024) > 5000) {
            triggerMessage('Image allows maximum of 5MB file size')
            return
        }
        let type = ''
        if (e.target.files[0].type === 'image/jpeg') {
            type = 'jpg'
        }
        if (e.target.files[0].type === 'image/png') {
            type = 'png'
        }
        if (e.target.files[0].type === 'image/svg+xml') {
            type = 'svg'
        }
        let acimage = await getImage(e.target.files[0])

        if (bannerType === 'desktop') {
            if (acimage.width < 1000 || acimage.height < 400) {
                triggerMessage(
                    'Upload valid image. Check the dimension in given notes'
                )
                return false
            }
            setAspectWidth(1000)
            setAspectHeight(400)
        }
        if (bannerType === 'mobile') {
            if (acimage.width < 360 || acimage.height < 640) {
                triggerMessage(
                    'Upload valid image. Check the dimension in given notes'
                )
                return false
            }
            setAspectWidth(360)
            setAspectHeight(640)
        }
        setImgType(type)
        let reader = new FileReader()
        reader.onload = (e) => {
            setImageForCrop(e.target.result)
        }

        reader.readAsDataURL(image)
        setImageCropModal(true)
    }

    const handleCropImage = (croppedImage, fileName) => {
        setImage(croppedImage)
    }

    return (
        <>
            <div
                ref={ref}
                className={uploadClass}
                {...(draggable ? draggableProp : { onClick: triggerUpload })}
                {...rest}
            >
                <input
                    className={uploadInputClass}
                    type="file"
                    ref={fileInputField}
                    onChange={onNewFileUpload}
                    disabled={disabled}
                    multiple={multiple}
                    accept={accept}
                    title=""
                    value=""
                    {...field}
                    {...rest}
                ></input>
                {renderChildren()}
            </div>
            {tip}
            {showList && (
                <div className="upload-file-list">
                    {files.map((file, index) => (
                        <FileItem file={file} key={file.name + index}>
                            <CloseButton
                                onClick={() => removeFile(index)}
                                className="upload-file-remove"
                            />
                        </FileItem>
                    ))}
                </div>
            )}
            {imageCropModal && (
                <ImageCrop
                    isOpen={imageCropModal}
                    src={imageForCrop}
                    toggle={() => setImageCropModal(!imageCropModal)}
                    cancelClick={() => {
                        setImageCropModal(!imageCropModal)
                        setImageForCrop(null)
                    }}
                    doneClick={() => {
                        setFiles((prev) => [...prev, image])
                        onChange?.([...files, image])
                        setImageCropModal(false)
                    }}
                    doneBtnColor="primary"
                    isHorizontal={true}
                    cancelBtnColor="flat-warning"
                    setCropImage={handleCropImage}
                    ext={imgType}
                    // showLoader={showLoader}
                    aspectWidth={aspectWidth}
                    aspectHeight={aspectHeight}
                />
            )}
        </>
    )
})

Upload.propTypes = {
    uploadLimit: PropTypes.number,
    draggable: PropTypes.bool,
    disabled: PropTypes.bool,
    showList: PropTypes.bool,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Upload.defaultProps = {
    draggable: false,
    showList: true,
    disabled: false,
    fileList: [],
}

export default Upload
